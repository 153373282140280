<template>
  <div>
    <el-dialog title="新增整卷出卷"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <!-- 在校本资源那里有用这个模板，后期如果修改这里  对应的需要去修改校本资源 -->
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="指定年级"
                          prop="given_garde">
              <el-select v-model="form.given_garde"
                         clearable
                         style="width:100%"
                         @change="gradeChange"
                         placeholder="选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="指定班级"
                          prop="given_class">
              <el-select v-model="form.given_class"
                         clearable
                         filterable
                         @change="classChange"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name "
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷科目"
                          prop="subjectId">
              <el-select v-model="form.subjectId"
                         style="width:100%"
                         :disabled="isResource"
                         @change="subjectChage"
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷地区"
                          prop="district_id">
              <el-select v-model="form.district_id"
                         style="width:100%"
                         filterable
                         @change="changeDistrict"
                         :disabled="isResource"
                         placeholder="选择">
                <el-option v-for="item in districtList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷年份"
                          prop="question_year">
              <el-select v-model="form.question_year"
                         style="width:100%"
                         filterable
                         @change="changeYear"
                         :disabled="isResource"
                         placeholder="选择">
                <el-option v-for="item in year"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8"
                  v-if="is_detailed==1">
            <el-form-item label="详细来源">
              <el-switch v-model="form.source_paper"
                         :active-value="1"
                         :inactive-value="0"
                         active-text="是"
                         inactive-text="否"
                         @change="changeSourceType" />
            </el-form-item>
          </el-col>

          <el-col :span="8"
                  v-show="form.source_paper==0">
            <el-form-item label="试卷来源">
              <el-select v-model="form.source_id"
                         :disabled="fromResource"
                         filterable
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-show="this.form.source_paper==1">
            <el-form-item label="试卷来源">
              <el-select v-model="form.source_id"
                         :disabled="fromResource"
                         filterable
                         clearable
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in sourceList1"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                  <span style="float: left">{{ item.source_name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px"
                        v-if="item.has_paper_source">在教师试卷{{ item.has_paper_source }}使用过</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item label="试卷来源">
              <el-select v-model="form.source_id"
                         :disabled="fromResource"
                         filterable
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="8">
            <el-form-item label="试卷学期">
              <el-select v-model="form.semester"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in semesterList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷类型">
              <el-select v-model="form.edu_paper_type"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in paperTypeList"
                           :key="item.paper_type_id"
                           :label="item.paper_type_name"
                           :value="item.paper_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷时长">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_duration" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              style="width:100%"
                              type="datetime"
                              @focus="$forbid"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

        </el-row>
        <p>
          说明：
        </p>
        <p>
          1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </p>
        <p>
          2.组的试卷必须提交才能导出或提供给学生做题。
        </p>
        <p>
          3.指定年级和指定班级二选一
        </p>

        <!-- <p>
          （注：试卷类型添加“模考统考”，由上到下依次为“单元检测”、“月考联考”、“期中考试”、“期
          末考试”、“模考统考”）
        </p> -->
        <el-row :gutter="10"
                style="text-align:center;margin-top:20px">
          <el-col :span="24">
            <el-button type="primary"
                       @click="toInfo">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import { getSourceList as getSourceListApi } from '@/api/source.js'
export default {
  data () {
    return {
      form: {},
      width: '900px',
      showList: [],
      dialogVisible: false,
      testingList: [],
      subjectList: [],
      paperTypeList: [],
      gradeList: [],
      classList: [],
      sourceList: [],
      districtList: [],
      semesterList: [
        { label: '上学期', value: 0 },
        { label: '下学期', value: 1 },
        { label: '未指定', value: 2 },
      ],
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        source_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        semester: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_class: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_paper_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      year: [],
      semesterList: [
        { id: 0, label: '上学期' },
        { id: 1, label: '下学期' },
        { id: 2, label: '未指定' },
      ],
      // 选择校本资源为来源的时候
      isResource: false,
      // 从校本资源页面点击组卷的时候
      fromResource: false,
      is_detailed: 0,
      sourceList1: [],
    }
  },
  mounted () {
    this.is_detailed = JSON.parse(window.localStorage.getItem('userInfo')).is_detailed
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  watch: {
    'form.source_id': {
      handler (newVal, oldVal) {
        if (newVal > 3 && this.form.source_paper == 0) {
          this.getDetail()
        } else if (oldVal > 3 && newVal < 3 && this.form.source_paper == 0) {
          this.reset()
        }
      }
    },
    'form.given_garde': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.addRules()
        }
      }
    },
    'form.given_class': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.addRules()
        }
      },
    }
  },
  methods: {

    changeDistrict () {
      // 详细来源与地区相关
      if (this.form.source_paper == 1) {

        this.getSourceList1()
      }
    },
    async getSourceList1 () {
      // this.form.source_id = ''
      this.sourceList1 = []
      if (!this.form.subjectId || !this.form.district_id) return
      let params = {
        subject_id: this.form.subjectId,
        district_id: this.form.district_id,
        year: this.form.question_year,
        grade_id: this.form.given_garde,
        show_history: 1
      }
      const { data } = await getSourceListApi(params)
      this.sourceList1 = data.list
      if (this.form.source_id && this.sourceList1 && this.sourceList1.length > 0) {
        if (this.sourceList1.filter(item => item.source_id == this.form.source_id).length == 0) {
          this.form.source_id = this.sourceList1[0].source_id
        }
      } else if (this.form.source_id && this.sourceList1 && this.sourceList1.length == 0) {
        this.form.source_id = ''
      } else if (!this.form.source_id) {
        // this.form.source_id = this.sourceList1[0].source_id
        this.form.source_id = ''

      }
    },
    getGradeAndClass () {
      this.$http({
        url: '/api/v1/public/subject_get_grade_and_class',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        console.log('resaaaa', res)
        this.classList = res.data.class_data
        this.gradeList = res.data.grade_data
      })
    },
    clearGradeAndClass () {
      this.form.given_garde = ''
      this.form.given_class = ''
      this.classList = []
      this.gradeList = []
    },
    // 赋值科目id，year，地区id
    setSubjectAndYearAndDis (subject, year, dis) {
      this.form.subjectId = subject
      if (year) {
        this.form.question_year = year
      }

      this.form.district_id = dis
      this.subjectList = []
    },
    reset () {
      this.form.edu_paper_type = ''
      this.paperTypeList = []
      // this.isResource = false
      this.clearGradeAndClass()
      this.setSubjectAndYearAndDis('', '', '')
      this.getClass()
      this.getGradeList()
      this.$nextTick(() => {
        this.isResource = false
        console.log(this.isResource)
      })

    },
    getDetail () {
      this.$http({
        url: '/api/v1/file/source_detail',
        method: 'get',
        params: {
          source_id: this.form.source_id
        }
      }).then(res => {
        let data = res.data
        this.setSubjectAndYearAndDis(data.subject_id, data.year, data.paper_district_id)
        this.clearGradeAndClass()
        this.subjectList = [
          { subject_id: data.subject_id, subject_name: data.subject_name }
        ]
        this.isResource = true
        this.getGradeAndClass()
      })
    },

    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId
        form.module_id = 3

        this.$http({
          url: '/api/v1/public/set_record',
          method: 'post',
          data: form
        }).then(res => {
          // this.$notify({
          //   title: '提示',
          //   message: '操作成功!',
          //   type: 'success'
          // });
        })

        this.$http({
          url: '/api/v1/combination/whole_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$router.push('/testPapercomposition/autonomously/addDetails?edu_paper_id=' + res.data.edu_paper_id)
        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/public/select_district',
        method: 'get',
      }).then(res => {
        this.districtList = Object.values(res.data)
        // if (!this.form.district_id || this.districtList.filter(item => item.paper_district_id == this.form.district_id).length == 0) {
        //   this.form.district_id = this.districtList[0].paper_district_id
        // }
        if (this.form.source_paper == 1) {
          this.getSourceList1()
        }
      })
    },

    //是否显示详细来源
    changeSourceType () {
      if (this.form.source_paper == 1) {
        if (this.isResource) {
          this.getClass()
          this.getGradeList()
          this.isResource = false
        }
        this.getSourceList1()
        // this.reset()
      }
      if (this.form.source_paper == 0 && this.sourceList.length == 0) {
        this.getSourceList()
      }
      this.form.source_id = ''
    },
    changeYear () {
      if (this.form.source_paper == 1) {
        this.getSourceList1()
      }
    },
    getYearList () {
      // this.$http({
      //   url: '/api/v1/public/year_all',
      //   method: 'get',
      // }).then(res => {
      //   this.year = res.data
      // })
      // 获取当前年份
      let currentYear = new Date().getFullYear();
      if (new Date().getMonth() >= 8) {
        currentYear += 1
      }
      let arr = []
      // 循环输出从2018年到当前年份的年份
      for (let year = 2018; year <= currentYear; year++) {
        arr.push(year)
      }
      this.year = arr.reverse()
    },
    addRules () {
      let rules = JSON.parse(JSON.stringify(this.rules))
      if (!this.form.given_class && !this.form.given_garde) {
        rules.given_class = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
        rules.given_garde = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      } else if (this.form.given_garde) {
        delete rules.given_class
        rules.given_garde = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      } else if (this.form.given_class) {
        rules.given_class = [
          { required: true, message: '请选择', trigger: 'change' },
        ]
        delete rules.given_garde
      }
      this.$set(this, 'rules', rules)


      // this.subjectList = []
      if (this.form.source_id > 3) return
      this.form.subjectId = ''
      // this.getSubjectList()
    },
    // classChange () {
    //   this.paperTypeList = []
    //   this.form.given_garde = ''
    //   this.addRules()
    //   if (this.form.given_class == '') {
    //     return
    //   }
    //   this.subjectChage()
    // },
    classChange (value) {
      this.paperTypeList = []

      if (this.form.given_class == '') {
        return
      }
      this.addRules()

    },
    subjectChage () {
      this.paperTypeList = []
      this.form.edu_paper_type = ''

      if (this.form.given_garde == '' && this.form.given_class == '' && this.form.subjectId == '') return

      if (this.form.given_garde) {
        var form = {
          grade_id: this.form.given_garde,
          subject_id: this.form.subjectId
        }

      } else {
        var special_class_subject_id = this.subjectList.find(item => item.subject_id == this.form.subjectId).special_class_subject_id
        var form = {
          special_class_subject_id: special_class_subject_id
        }
      }
      this.getDirList()
      this.getPaperTypeList(form)
    },
    // gradeChange () {
    //   this.paperTypeList = []
    //   this.form.given_class = ''

    //   if (this.form.given_garde == '') return
    //   this.addRules()
    //   this.subjectChage()
    // },
    gradeChange (value) {
      this.paperTypeList = []
      this.form.given_class = ''
      if (this.form.given_garde == '') return
      this.addRules()
      this.subjectList = []
      this.getClass()
      // if (this.form.source_id > 3 && this.form.source_paper == 0) return
      this.getSubjectList()
    },
    getClass () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,

        }
      }).then(res => {
        this.classList = res.data
        if (this.form.given_class && !this.form.given_garde) {
          if (this.classList && this.classList.filter(item => item.id == this.form.given_class).length == 0) {
            this.form.given_class = this.classList[0].id
            this.classChange()
          } else if (!this.classList || this.classList == 0) {
            this.form.given_class = ''
          } else {
            this.classChange()
          }
        }
      })
    },
    getSourceList () {
      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
        this.getOther()
      })
    },
    getOther () {
      this.$http({
        url: '/api/v1/file/paper_source',
        method: 'get',
      }).then(res => {
        res.data.list.forEach(item => {
          this.sourceList.push({
            source_name: item.paper_name,
            source_id: item.source_id
          })
        });
      })
    },
    getList () {
      this.getGradeList()
      this.getDirList()
      this.getSourceList()
      this.getYearList()
      this.getClass()
      if (!this.form.given_garde && !this.form.given_class) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
          // class_id: this.form.given_class
        }
      }).then(res => {
        this.subjectList = res.data
      })
    },
    getSubjectList () {
      if (!this.form.given_garde && !this.form.given_class) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
          // class_id: this.form.given_class
        }
      }).then(res => {
        this.subjectList = res.data
      })
      this.subjectChage()
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
        if (this.form.given_garde) {

          if (this.gradeList.filter(item => item.grade_id == this.form.given_garde).length == 0) {

            this.form.given_garde = this.gradeList[0].grade_id
          }

          this.gradeChange()
        }
      })
    },
    getPaperTypeList (form) {
      this.$http({
        url: '/api/v1/public/paper_type',
        method: 'GET',
        params: form
      }).then(res => {
        if (res.data.paper_type_id) {
          this.paperTypeList = [res.data]
        } else {
          this.paperTypeList = Object.values(res.data)
        }
        if (this.paperTypeList.length == 1) {
          this.form.edu_paper_type = this.paperTypeList[0].paper_type_id
        }
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
      this.form.source_id = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>